/* eslint-disable */
/* Misc Functions */

/**
 * Function to sanitize third-party HTML content
 * @param {string} HTML HTML content that require sanitization
 * @returns sanitized HTML
 */
export function sanitizeHTML(HTML) {
  /**
   * possible improvements:
   * - add conditional filtering to cater for newlaw generated content
   *   that contains <a> tag or other vulnerable tag
   *
   * - filter unnecessary elements in other third-party HTML content
   */

  HTML = HTML
    // usage unknown (copied from legacy code)
    .replace(
      /link="blue"(?: style="word-wrap:break-word")? vlink="#954F72">|style="word-wrap:break-word">/g,
      ""
    )
    .replace('link="#003657" style="word-wrap:break-word" vlink="#00C4B4">', "")

    // remove BAILII's banner
    .replace(
      /<div style="background-color: #feeddb">[\s\S]*?(If you found BAILII useful today, could you please make a contribution)[\s\S]*?<\/div>/g,
      ""
    )

    // sanitize FreeLawProject's content
    .replace(
      /<header[\s\S]*?(Donate Banner)[\s\S]*?(navbar)[\s\S]*?<\/header>/g,
      ""
    )
    .replace(
      /<div[^>]*?sidebar[\s\S]*?<\/div>[\s]*<article class="col-sm-9"/g,
      "<article"
    )
    .replace(
      /<div class="row base-newsletter hidden-print"[\s\S]*?<\/footer>/,
      ""
    )

    // remove any font for ExportPDF to function properly
    .replace(/font-family: (&quot;.*?&quot;[^;]*|[^;]*); */g, "");

  const doc = new DOMParser().parseFromString(HTML, "text/html");

  // replace <a> tag with pure text content
  doc.querySelectorAll("a").forEach((link) => {
    const textNode = document.createTextNode(link.textContent);
    link.parentNode.replaceChild(textNode, link);
  });

  // remove vulnerable elements
  ["button", "input", "img", "script"].forEach((el) => {
    doc.querySelectorAll(el).forEach((i) => i.remove());
  });

  // Additional cleanup specific to the given backend data
  doc.querySelectorAll(".pdf-viewer-page").forEach((page) => {
    // Remove data attributes and inline styles
    page.removeAttribute("data-echo-background");
    page.removeAttribute("data-page");
    page.removeAttribute("style");

    // Remove pdf-viewer-line spans
    page.querySelectorAll(".pdf-viewer-line").forEach((line) => {
      line.removeAttribute("style");
    });

    // Remove pdf-viewer-word spans
    page.querySelectorAll(".pdf-viewer-word").forEach((word) => {
      word.removeAttribute("style");
    });
  });

  // Return the sanitized HTML as a string
  return doc.documentElement.innerHTML;
}



/**
 * Function that calculates the length of the given text
 * @param {string} text 
 * @returns Number of words in the given string
 */
export function getWordCount(text) {
  return text?.split(" ").filter(w => w !== "").length ?? 0;
}

/**
 * Function to get the display name for user.
 * This function is accessible with Vue filter too, using `| username`
 * @param {string} name User's full name
 * @returns User's display name
 */
export function extractDisplayName(name) {
  return name.split(" ").slice(0, 2).join(" ");
}

import Auth from "./Auth";
/**
 * @param {*} me Vue's `this` instance
 */
export function Authenticate(me) {
  Auth.getMe().then(res => {
    const UI = res.data.data;
    if ([
      undefined, "undefined", null, "null"
    ].includes(UI.nexlaw_credits)) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      me.$router.push("/login");
    } else {
      localStorage.setItem("userInfo", JSON.stringify(UI));
    }
  })
}

/**
 * A loop function to automatically retieve new Data
 * from given API endpoint.
 * Require `loop` variable in Vue's `this` instance to check for loop condition
 * @param {CreateComponentPublicInstance} me Vue's `this` instance
 * @param {Function} epFunc EndPoint Function that retrieve data
 * @param {*} param Parameter to pass into `epFunc`
 * @param {Function} func Data process function that determine if looping is required
 * @param {boolean} AUTH Flag to define whether Authenticate function will be invoked before running the Data process function
 * 
 * @param {string} _path `null` *DO NOT REQUIRE INPUT. THIS PARAMETER WILL BE USED BY THE LOOP FUNCTION INTERNALLY*
 */
export function loopUpdate(me, epFunc, param, func, AUTH = false, interval = 20000, _path = null) {
  me.loop = false;

  // perform path check before sending request
  if (_path === null) _path = me.$route.fullPath;
  else if (_path !== me.$router.fullPath) clearTimeout(me.loop);

  // retrieve data from given EndPoint
  epFunc(param)
    .then(res => {
      // perform authentication & update userInfo
      if (AUTH) Authenticate(me);

      // invoke data process function
      func(res);

      // loop if this.loop === true
      // update the data after ~20s
      me.loop = me.loop
        ? setTimeout(() => {
          console.log("inLoop", _path);
          loopUpdate(me, epFunc, param, func, AUTH, interval, _path);
        }, interval)
        : false;
    })
    .catch(err => { console.log(err); });
}

/**
 * Data process function to convert input object into FormData
 * @param {object} item Object to be converted
 * @returns FormData with the elements in `item`
 */
export function createFormData(item) {
  let FD = new FormData();
  Object.entries(item).forEach(e => {
    if (typeof e[1] === "array") {
      for (let i = 0; i < e[1].length; i++) {
        FD.append(e[0], e[1][i]);
      }
    } else {
      FD.append(e[0], e[1]);
    }
  });
  return FD;
}

/**
 * Convert all RegExp symbols to escaped characters
 * @param {string} string String to be converted
 * @returns Converted string
 */
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
/* -------------- */

/* Input Validation Functions */

/**
 * Function to perform input validations
 * 
 * Error messages:
 * 
 *    "empty"   : indicate empty input /
 *    "invalid" : indicate general invalid input /
 *    "max"     : indicate the input exceeded the given max length /
 *    "missing" : indicate incomplete input in a collection /
 *    "courts"  : indicate incomplete input in a jurisdiction & courts object /
 *    "file"    : indicate invalid file extension /
 * 
 * @param {*} input User input that require validation/formatting
 * @param {string} type Specify type of validation/formatting
 * @param {Array} args Extra arguments for additional checks
 * @returns Result Array: [0] Result (Boolean), [1] (Passed) Formatted Value / (Failed) Error Message
 */
export function validateInput(input, type, args = []) {
  var VALID = [false, "N/A"];
  var Formatted = [];

  /**
   * Error messages:
   *    "empty"   : indicate empty input
   *    "invalid" : indicate general invalid input
   *    "max"     : indicate the input exceeded the given max length
   *    "missing" : indicate incomplete input in a collection
   *    "courts"  : indicate incomplete input in a jurisdiction & courts object
   *    "file"    : indicate invalid file extension
   */
  switch (type) {
    case "string":
      // remove leading, tailing & duplicated spaces
      input = input.trim().replace(/\s+/g, " ");

      // check empty
      if(!input.length) {
        VALID = [false, "empty"]; break;
      }

      // check max
      var max = args.find(arg => arg.startsWith("max"));
      if (max) {
        VALID[0] = input.split(" ").length < parseInt(max.replace("max", ""));
        VALID[1] = VALID[0] ? input : "max";
      }

      // check filename
      if (args.includes("filename")) {
        const FileExt = [ "pdf", "doc", "docx", "png", "jpeg", "jpg" ];

        VALID[0] = !/\(|\)|,|\//.test(input);
        input = input.split(".");
        if (FileExt.includes(input[input.length - 1])) {
          input.splice(input.length - 1);
        }
        VALID[1] = VALID[0]
          ? input.join(".")
          : "invalid";
      }

      // check email
      if (args.includes("email")) {
        VALID[0] = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input);
        VALID[1] = VALID[0] ? input : "invalid";
      }

      // marked as VALID if there is no error
      if (VALID[1] === "N/A") VALID = [true, input];
      break;

    // for array of elements where every fields in each element
    // shoud have valid value
    // case "collection":
    //   VALID[0] = true;
    //   input.map(ent => {
    //     const MAP = Object.entries(ent).map(([k, x]) => {
    //       // apply basic formatting for string
    //       if (typeof ent[k] === "string") {
    //         ent[k] = ent[k].trim().replace(/\s+/g, " ");
    //       }

    //       // verify value
    //       return ["", null, undefined].includes(x);
    //     });

    //     // valid input
    //     if (!MAP.includes(true)) Formatted.push(ent);

    //     // invalid input (missing value in some fields)
    //     else if (MAP.includes(false)) VALID = [false, "missing"];
    //   });

    //   if (VALID[0]) VALID[1] = Formatted;
    //   break;

    case "collection":
    VALID[0] = true;
    input.map(ent => {
      // Extract only the 'statement' field if the object has a 'statement'
      // if (ent.statement) {
      //   Formatted.push(ent.statement.trim().replace(/\s+/g, " "));
      // }

      const MAP = Object.entries(ent).map(([k, x]) => {
        // apply basic formatting for string
        if (typeof ent[k] === "string") {
          ent[k] = ent[k].trim().replace(/\s+/g, " ");
        }

        // verify value
        return ["", null, undefined].includes(x);
      });

      // valid input
      if (!MAP.includes(true) && ent.statement) {
        Formatted.push(ent.statement);
      }

      // invalid input (missing value in some fields)
      else if (MAP.includes(false)) {
        VALID = [false, "missing"];
      }
    });

    // Set the formatted witness statements array to VALID[1]
    if (VALID[0]) VALID[1] = Formatted;
    break;

    
    case "jurisdiction":
      // multi-jurisdictions
      if (typeof input === "object") {
        VALID[0] = input.length > 0;
        VALID[1] = VALID[0]
          ? input.map(j => j === "United States of America" ? "USA" : j)
          : "empty";
      }
      // single jurisdiction
      else {
        // verify & cast the input to desired format
        VALID[0] = !["", null].includes(input);
        VALID[1] = VALID[0]
          ? (input === "United States of America"
              ? "USA"
              : input)
          : "empty";
      }
      break;

    case "courts":
      VALID[0] = true;
      Formatted = {};
      Object.entries(input).map(([j, c]) => {
        // general handler
        if (!args.includes("LES")) {
          j = c.jurisdiction;
          c = c.courts;
        }

        // skip empty entries
        if ((j === "" || j === null) && c.length === 0) return;

        // format input
        Formatted[
          (j == "United States of America")
            ? (args.includes("LES") ? "USA" : "United States")
            : j
        ] = c.map(crt =>
          (crt.id === `all${j}` || crt === `all${j}`) ? "all" : (crt.id ?? crt)
        );

        if (c.length === 0) {
          VALID = [false, "courts"];
        }
      });

      if (JSON.stringify(Formatted) === "{}") {
        VALID = [false, "empty"];
      } else if (VALID[0]) {
        VALID[1] = JSON.stringify(Formatted);
      }
      break;

    case "file":
      if (!input) {
        VALID[1] = "empty";
      } else {
        if (args.includes(input.name.split(".").pop())) {
          VALID = [true, input]
        } else {
          VALID[1] = "file"
        }
      }
      break;
  }

  return VALID;
}

/**
 * Shortcut function to invoke validateInput() function on multiple input fields
 * @param {Array} arr Array of validateInput() parameters (input, type, args)
 * @returns Array of Results from validateInput()
 */
export function groupValidateInput(arr) {
  return arr.map(x => validateInput(...x));
}
/* -------------------------- */

/* Export Functions */
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

// Configs for Export Functions
const editor_color = [
  'black', 'white', 'grey',
  'red', 'orange', 'yellow',
  'yellowgreen', 'limegreen', 'green',
  'cyan', 'blue', 'purple',
  'magenta', 'pink',
];
const editor_color_style = editor_color.map(color => {
  return `.ql-bg-${color}{background-color:${color}}.ql-color-${color}{color:${color}}`;
}).join('');

const HEADER = `
  <html xmlns:0="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="utf-8"><title></title><meta name="description" content=""><style>p{margin:0px;font-family:Times New Roman}`
    + editor_color_style
    + `</style></head><body>
`;
const FOOTER = `</body></html>`;
const PDF_PageHeader = "\n\0    By NexLaw";

/**
 * Shortcut function to invoke ExportDataDoc() and/or ExportDataPDF based on the given parameter
 * @param {string} HTML The HTML content that is required to be exported
 * @param {Array} Options Boolean Array that specify export to [0]Document and [1]PDF
 * @returns Status Array that indicate successful export of [0]Document and [1]PDF
 */
export function ExportData(HTML, [doc, pdf]) {
  var status = [null, null];

  if (doc) {
    status[0] = false; ExportDataDoc(HTML); status[0] = true;
  }
  if (pdf) {
    status[1] = false; ExportDataPDF(HTML, true); status[1] = true;
  }

  // null : the function is not instructed to export to the type
  // false: error while converting the HTMLdata
  // true : converted and downloaded successfully
  return status;
}

/**
 * Export given HTML content to .doc file
 * @param {string} HTML The HTML content that is required to be exported
 */
export function ExportDataDoc(HTML) {
  HTML = `${HEADER}${HTML}${FOOTER}`
    .replace(
      /<p class="ql-indent-(\d)">/g,
      function(match, indentLevel) {
        return `<p style="margin:0;margin-left:${indentLevel * 40}px">`;
      }
    );

  // create temp <a> tag
  var File = document.createElement('a');
  document.body.appendChild(File);

  // encode & download doc
  File.href = `data:application/vnd.ms-word;charset=utf-8,`
    + encodeURIComponent(HTML);
  File.download = 'document.doc';
  File.click();

  // remove temp
  document.body.removeChild(File);
}

/**
 * Export given HTML content to .pdf file
 * @param {string} HTML The HTML content that is required to be exported
 * @param {boolean} header [true] add "NexLaw" header when constructing PDF, [false] no header
 */
export function ExportDataPDF(HTML, header = false) {
  HTML = `${HEADER}${HTML}${FOOTER}`
    .replace(
      /<p class="ql-indent-(\d)">/g,
      function(match, indentLevel) {
        return `<p style="margin:0;margin-left:${indentLevel * 40}px">`;
      }
    );

  
  HTML = HTML
    // convert highlight background color
    .replace(
      /<span class="ql-bg-(\w+)">/g,
      `<span style="background-color:$1">`
    )
    // convert text color
    .replace(
      /<span class="ql-color-(\w+)">/g,
      `<span style="color:$1">`
    )
    // remove extra spacing
    .replace(
      /<p><br><\/p><p><br><\/p>/g,
      `<p class="spacing"><br></p>`
    )
    .replace(
      /<p style="margin:0;margin-left:(\d+)px"><br><\/p>/g,
      function() {
        return "";
      }
    )
    .replace(
      /<p><br><\/p>/g, ""
    );

  // create pdf & download
  const docDefinition = {
    content: htmlToPdfmake(HTML),
  }
  if (header) {
    docDefinition.header = PDF_PageHeader;
  }
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.createPdf(docDefinition).download();
}
/* ---------------- */